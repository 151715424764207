
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";

import Multiselect from "@vueform/multiselect";

// Import loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// end import loading overlay

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Multiselect,
    Loading, //loading overlay
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {

      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      tableData: [],
      listRole: [],
      paging:{
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      idRole: "",
    }
  },

  beforeMount() {
    const store = useStore();

    store.dispatch(Actions.VERIFY_AUTH_BANK);

    this.idRole = JSON.parse(localStorage.getItem("idRoleSelected")!);

    this.getData(this.paging.size, this.paging.page);

    this.getListRole();


    setCurrentPageBreadcrumbs("User", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page){

      // loading overlay tampil
      this.isLoading = true;

      localStorage.setItem("idRoleSelected", JSON.stringify(this.idRole));

      const toast = useToast();

      let search = '';
      if (this.name) {
        search = 'name=' + this.name + '&'; 
      } else {
        search
      }

      let role = '';
      if (this.idRole) {
        search = 'userRole=' + this.idRole + '&'; 
      } else {
        role
      }
      
      let role_Name = '';
      if (this.idRole && this.name) {
        search = 'name=' + this.name + '&userRole=' + this.idRole + '&'; 
      } else {
        role
      }

      ApiService.getWithoutSlug('crmv2/main_user/user/all?' + role + search + role_Name + 'page=' + page + '&size=' + size + '&sort=name&dir=1')
        .then(({ data }) => {
          this.tableData = data.content
          this.paging.page = data.page
          this.paging.size =  data.size
          this.paging.totalElements = data.totalElements
          this.paging.totalPages = data.totalPages

          this.isLoading = false;
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);

          this.isLoading = false;
        })
    },

    getListRole(){
      // loading overlay tampil
      this.isLoading = true;

      const toast = useToast();

      ApiService.getWithoutSlug("crmv2/main_user/user/combo_role")
        .then(({ data }) => {
          this.listRole = data;

          this.isLoading = false;
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);

          this.isLoading = false;
        })
    },

    pilihRole(event) {
      console.log(event);
      if (event) {
        this.idRole = event;
      } else {
        this.idRole = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal
        .fire({
          title: "Apakah Anda Yakin ?",
          text: `Data yang telah dihapus tidak dapat dikembalikan !`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Ya, hapus !",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(item);
          }
        });
    },

    deleteRow(item) {
      // loading overlay tampil
      this.isLoading = true;

      const toast = useToast();

      ApiService
        .delete('crmv2/main_user/user/' + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil")
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.isLoading = false;
        });
    },

    range(min, max) {
      const arr: any[] = []
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

  },

});
